/* ###### Iconbar  ###### */

@media (max-width: 991.98px) {
  .main-iconbar-show {
    .main-iconbar {
      display: flex;
    }

    .main-iconbar-aside {
      display: block;
    }
  }
}

.main-iconbar {
  width: 64px;
  display: none;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  background-color: $white;
  position: fixed;
  top: 63px;
  left: 0;
  height: 100%;
  background-color: $white;
  z-index: 500;
  border-right: 1px solid #e8e8f7;
  border-top: 1px solid #e8e8f7;

  .nav {
    flex: 1;
    flex-direction: column;
    align-items: center;
    padding: 5px 0;
  }

  .nav-link {
    position: relative;
    padding: 0;
    color: #00154f;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      font-size: 20px;

      &.typcn {
        line-height: 1;

        &::before {
          width: auto;
        }
      }

      &.typcn-device-laptop, &.typcn-th-large-outline {
        font-size: 25px;
      }

      &.fa-desktop {
        font-size: 23px;
      }
    }

    &:hover, &:focus {
      color: #3c4858;
    }
    + .nav-link {
      margin-top: 10px;
    }
  }
}

@media (min-width: 992px) {
  .main-iconbar {
    display: flex;
  }
}

.main-iconbar-logo {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  line-height: .9;
  color: #00154f;
  flex-shrink: 0;
  margin-bottom: 20px;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 6px;
    left: 20px;
    height: 3px;
    width: 24px;
    border-radius: 2px;
  }

  &:hover, &:focus {
    color: #00154f;
  }
}

.main-iconbar-bottom {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-iconbar-help {
  line-height: 0;
  font-size: 22px;
  color: #a8afc7;
  margin-bottom: 20px;

  &:hover, &:focus {
    color: #00154f;
  }
}

.main-iconbar-aside {
  width: 230px;
  height: 100%;
  background-color: $white;
  border-right: 1px solid #e8e8f7;
  border-top: 1px solid #e8e8f7;
  position: fixed;
  top: 63px;
  left: 64px;
  overflow-y: auto;
  z-index: 600;
  display: none;
}

@media (min-width: 1200px) {
  .main-iconbar-aside {
    border-right: 0;
    z-index: 500;
  }
}

@media (min-width: 992px) {
  .main-iconbar-aside.show {
    display: block;
  }
}

@media (min-width: 1200px) {
  .main-iconbar-aside.show + .main-content {
    margin-left: 294px;
  }
}

.main-iconbar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  padding-left: 0;

  .main-logo {
    line-height: 1;
  }
}

.main-iconbar-toggle-menu {
  display: block;
  position: relative;
  line-height: 0;
  color: #00154f;
  font-size: 28px;
  opacity: .5;

  &:hover, &:focus {
    opacity: 1;
    color: #00154f;
  }

  i:first-child {
    display: none;
  }
}

@media (min-width: 1200px) {
  .main-iconbar-toggle-menu {
    font-size: 24px;

    &::before {
      content: '';
      position: absolute;
      top: 3px;
      bottom: 3px;
      left: -1px;
      border-left: 2px solid $black;
    }

    i {
      &:first-child {
        display: block;
      }

      &:last-child {
        display: none;
      }
    }
  }
}

.main-iconbar-body {
  padding: 20px;
  height: calc(100vh - 68px);

  .nav {
    flex-direction: column;
    padding-top: 10px;
  }

  .nav-item {
    position: relative;
    display: block;

    + .nav-item {
      margin-top: -1px;
      border-top: 1px dotted $background;
    }

    &.show .nav-sub {
      display: block;
    }
  }

  .nav-link {
    position: relative;
    color: #00154f;
    font-size: 13px;
    padding: 0;
    display: flex;
    align-items: center;
    height: 38px;

    i {
      color: #a8afc7;
      font-size: 16px;
      font-weight: 400;
      line-height: 0;
      margin-right: 10px;

      &.typcn {
        line-height: .9;

        &::before {
          width: auto;
        }
      }
    }

    &.with-sub {
      justify-content: space-between;

      &::after {
        content: '\f3d0';
        font-family: 'Ionicons';
        font-size: 11px;
        font-weight: 400;
        display: inline-block;
        position: relative;
        margin-left: auto;
        color: #a8afc7;
      }
    }
  }

  .nav-sub {
    list-style: none;
    padding: 0;
    margin: 0;
    border-top: 1px dotted $background;
    display: none;

    .nav-sub-item {
      position: relative;
      display: block;
      margin-left: 15px;

      + .nav-sub-item {
        border-top: 1px dotted $background;
      }
    }

    .nav-sub-link {
      display: flex;
      align-items: center;
      height: 36px;
      color: #00154f;
    }
  }
}

@media (min-width: 992px) {
  .main-iconbar-body .nav-link.with-sub::after {
    margin-left: 4px;
    font-size: 12px;
  }
}

.main-iconbar-title {
  font-size: 20px;
  font-weight: 700;
  color: #00154f;
  margin-bottom: 10px;
  line-height: 1;
}

.main-iconbar-text {
  display: block;
  font-size: 12px;
  color: #a8afc7;
  margin-bottom: 12px;
}

.main-iconbar-pane {
  margin-bottom: 20px;
  display: none;

  &.show {
    display: block;
  }
}

.main-iconbar-primary {
  padding-top: 0;

  .main-img-user::after {
    box-shadow: none;
  }

  .main-iconbar-logo {
    color: $white;
    height: 64px;

    &::after {
      display: none;
    }
  }

  .nav {
    justify-content: center;
  }

  .nav-link {
    width: 42px;
    height: 42px;
    color: $white-5;
    border-radius: 3px;
    transition: all 0.2s ease-in-out;

    &:hover, &:focus {
      color: $white;
      background-color: $white-1;
    }

    &.active {
      color: $white;
      background-color: $white-2;
    }
  }

  .main-iconbar-help {
    color: $white-5;

    &:hover, &:focus {
      color: $white;
    }
  }
}

@media (prefers-reduced-motion: reduce) {
  .main-iconbar-primary .nav-link {
    transition: none;
  }
}

.main-iconbar-aside-primary {
  width: 250px;
  background-color: #f1f2f9;
  box-shadow: 0 0 16px rgba(28, 39, 60, 0.08);
  border-right: 1px solid #e8e8f7;

  .main-iconbar-header {
    padding: 18px 25px;
    background-color: $white;
    border-bottom: 1px solid #e8e8f7;
  }

  .main-iconbar-body {
    padding: 20px 25px;

    .nav {
      padding-top: 0;
      padding-left: 0;
      border-left-width: 0;
    }

    .nav-item + .nav-item, .nav-sub, .nav-sub-item + .nav-sub-item {
      border-top-color: $background;
    }
  }

  .main-iconbar-toggle-menu {
    display: none;
  }
}

@media (min-width: 992px) {
  .main-iconbar-aside-primary.show + .main-content {
    margin-left: 64px;
  }
}
.main-icon-group {
    text-align: center;
    padding: 20px;
    color: $dark;
  
    i {
      line-height: 1;
      font-size: 24px;
    }
  }
  
  @media (min-width: 992px) {
    .main-icon-group i {
      font-size: 18px;
    }
  }

  .icons-list {
    list-style: none;
    margin: 0 -1px -1px 0;
    padding: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  
  .icons-list-item {
    text-align: center;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 1.4rem;
    font-size: 1.25rem;
    height: 55px;
    width: 55px;
    background: #f6f6ff;
    margin: 5px 5px;
    border-radius: 6px;
    border: 1px solid #e4e4f9;
  }
  
  .icons-list .flag-icon {
    border-radius: 0;
  }

/* ###### Iconbar  ###### */