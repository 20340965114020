/* ###### Breadcrumb ###### */

.breadcrumb-style1, .breadcrumb-style2, .breadcrumb-style3 {
  border-radius: 0;
  background-color: transparent;
  padding: 0;
}

.breadcrumb-style1 .breadcrumb-item, .breadcrumb-style2 .breadcrumb-item, .breadcrumb-style3 .breadcrumb-item {
  font-size: 15px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  letter-spacing: .5px;
}

.breadcrumb-style1 .breadcrumb-item a, .breadcrumb-style2 .breadcrumb-item a, .breadcrumb-style3 .breadcrumb-item a {
  color: #6f6f8e;
}

.breadcrumb-style1 .breadcrumb-item + .breadcrumb-item::before, .breadcrumb-style2 .breadcrumb-item + .breadcrumb-item::before, .breadcrumb-style3 .breadcrumb-item + .breadcrumb-item::before {
  color: #a8afc7;
}

.breadcrumb-style2 .breadcrumb-item + .breadcrumb-item::before, .breadcrumb-style3 .breadcrumb-item + .breadcrumb-item::before {
  width: 16px;
  text-align: center;
  font-family: 'Ionicons';
}

.breadcrumb-style2 .breadcrumb-item + .breadcrumb-item::before {
  content: '\f3d1';
}

.breadcrumb-style3 .breadcrumb-item + .breadcrumb-item::before {
  content: '\f287';
}

.main-content-breadcrumb {
  display: flex;
  align-items: center;
  font-size: 10px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #a8afc7;
  margin-bottom: 10px;

  span {
    position: relative;

    + span::before {
      content: '\f3d1';
      font-family: 'Ionicons';
      font-size: 12px;
      font-weight: 300;
      display: inline;
      margin: 0 5px;
      color: #b4bdce;
      line-height: 0;
      position: relative;
      top: 1px;
    }

    &:last-child {
      color: #3c4858;
    }
  }
}

.main-breadcrumbs {
  padding: 0;
  background-color: transparent;
  margin-bottom: 0;

  .breadcrumb-item {
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: .5px;
    font-weight: 700;

    a {
      color: #00154f;
      transition: all 0.2s ease-in-out;

      &:hover {
        color: #a8afc7;
      }
    }

    + .breadcrumb-item {
      padding-left: 8px;

      &::before {
        color: #a8afc7;
        padding-right: 8px;
        font-weight: 400;
      }
    }
  }
}

@media (prefers-reduced-motion: reduce) {
  .main-breadcrumbs .breadcrumb-item a {
    transition: none;
  }
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  border-radius: 0px;
}

.breadcrumb-item {
  + .breadcrumb-item {
    padding-left: 0.5rem;

    &::before {
      display: inline-block;
      padding-right: 0.5rem;
      color: #a8afc7;
      content: "/";
    }

    &:hover::before {
      text-decoration: underline;
      text-decoration: none;
    }
  }

  &.active {
    color: #a8afc7;
  }
}

.breadcrumb-5 a {
  text-decoration: none;
  outline: none;
  display: block;
  float: left;
  font-size: 12px;
  line-height: 36px;
  color: white;
  padding: 0 10px 0 39px;
  background: #666;
  background: linear-gradient(#666, #333);
  position: relative;

  &:first-child {
    padding-left: 46px;
    border-radius: 5px 0 0 5px;

    &:before {
      left: 14px;
    }
  }

  &:last-child {
    border-radius: 0 5px 5px 0;
    padding-right: 20px;
  }

  &.active, &:hover {
    background: #333;
    background: linear-gradient(#333, $black);
  }

  &.active:after {
    box-shadow: none;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: -18px;
    width: 36px;
    height: 36px;
    z-index: 1;
    transform: scale(0.707) rotate(45deg);
    box-shadow: 2px -2px 0 2px rgb(240, 242, 248), 3px -3px 0 2px $white-1;
    border-radius: 0 5px 0 50px;
  }

  &:last-child:after {
    content: none;
  }
}

.breadcrumb-3 {
  ol {
    position: relative;
    display: block;
    width: 100%;
    text-align: center;
  }

  li {
    display: contents;
    text-align: center;
  }
}

.breadcrumb-4 {
  ol {
    position: relative;
    display: block;
    width: 100%;
    text-align: right;
  }

  li {
    display: contents;
    text-align: right;
  }
}

/* ###### Breadcrumb ###### */